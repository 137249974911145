// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-js": () => import("./../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-buro-js": () => import("./../src/pages/product/buro.js" /* webpackChunkName: "component---src-pages-product-buro-js" */),
  "component---src-pages-product-buroem-js": () => import("./../src/pages/product/buroem.js" /* webpackChunkName: "component---src-pages-product-buroem-js" */),
  "component---src-pages-product-burolegal-js": () => import("./../src/pages/product/burolegal.js" /* webpackChunkName: "component---src-pages-product-burolegal-js" */),
  "component---src-pages-product-burolegalem-js": () => import("./../src/pages/product/burolegalem.js" /* webpackChunkName: "component---src-pages-product-burolegalem-js" */),
  "component---src-pages-product-estudio-js": () => import("./../src/pages/product/estudio.js" /* webpackChunkName: "component---src-pages-product-estudio-js" */),
  "component---src-pages-product-historia-js": () => import("./../src/pages/product/historia.js" /* webpackChunkName: "component---src-pages-product-historia-js" */),
  "component---src-pages-product-propiedades-js": () => import("./../src/pages/product/propiedades.js" /* webpackChunkName: "component---src-pages-product-propiedades-js" */),
  "component---src-pages-product-referencias-js": () => import("./../src/pages/product/referencias.js" /* webpackChunkName: "component---src-pages-product-referencias-js" */),
  "component---src-pages-product-sociedades-js": () => import("./../src/pages/product/sociedades.js" /* webpackChunkName: "component---src-pages-product-sociedades-js" */),
  "component---src-pages-product-visitas-js": () => import("./../src/pages/product/visitas.js" /* webpackChunkName: "component---src-pages-product-visitas-js" */)
}

